import { Flex, Heading, Text } from "@chakra-ui/react";
import * as React from "react";
import { Layout, Seo } from "../components";
import "../styles/screen.scss";

const seoBundle = {
  title: "Boise Steel Erectors",
  subtitle: "About JMF Steel Erectors",
  description:
    "Owyhee Roofing and Remodel is a family-run business local to Boise, Idaho",
  slug: "/about",
};

const AboutPage = ({ location }) => {
  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Flex
        maxW='full'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        bg={"ltOrange"}
        align='center'
        justify='center'
        flexDirection='column'
      >
        <Heading
          as='h1'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["3em", "4em"]}
          fontFamily='heading'
          lineHeight={["100%", null, null, "120%"]}
          textAlign='center'
        >
          About
        </Heading>
        <Text
          mt='20px'
          mb='7'
          textAlign='left'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
          className='cap'
        >
          Born and raised in Idaho, Jake Fillmore first began building steel
          structures in high school for his father’s company, then called
          Fillmore Custom Builders. The company’s work ranged from dairies and
          barns, airplane hangars, shops, and storage facilities to custom
          homes, remodels and everything in between. Jake took the company over
          when his father retired. With over 30 years of experience, JMF has
          become very specialized in steel construction of all project sizes.
        </Text>
        <Text
          mt='20px'
          mb='7'
          textAlign='left'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
          className='indent'
        >
          Chances are that we not only can build your structure, but we have
          built many like it already. JMF puts up quality buildings, aims for
          excellence in craftsmanship, and just does things the right way in
          general. With literal decades of experience, it is rare for much to
          surprise us. We’ve done projects all over the Treasure Valley and
          beyond for businesses such as Simplot Western Stockmen’s, CSDI,
          Champion Produce in Parma, and Farm Equipment USA in Fruitland and
          Jerome. Have a small project? You’ve come to the right place. We build
          smaller projects regularly, not just the large scale ones. Head over
          to our Frequently Asked Questions section to learn more about the
          process. If you still have questions, please call us. We truly want to
          be as helpful as possible.
        </Text>
        <Text
          mb='40px'
          mt='20px'
          fontSize={24}
          textAlign='center'
          maxW='800px'
          lineHeight='120%'
          fontWeight='bold'
          color='dkGrey'
        >
          Call us at <a href='tel:2088801062'>(208) 880-1062</a>
        </Text>
      </Flex>
    </Layout>
  );
};

export default AboutPage;
